/* Customize your main colors in :root variables */
:root {
  --main-background-color: #f2f4f8;
  --card-background-color:  #28242e;
 

  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color: #33af48;
}

body{
 /* background-color:var(--main-background-color);*/
 /*background-color: #166d3b;
background-image: linear-gradient(147deg, #0c0f0d 0%, #000000 74%);*/
background: rgb(10,10,10);
background: linear-gradient(180deg, rgb(5, 5, 5) 40%, rgb(24, 24, 24) 56%, rgb(7, 7, 7) 86%);
/* background: url("img/background.png");*/
  background-size: cover;
  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



