.logoTrueIcon {
    position: absolute;
    top: 0;
    left: 4px;
    width: 213px;
    height: 50px;
    object-fit: cover;
  }
  .rectangleDiv {
    position: relative;
    width: 213px;
    height: 50px;
    flex-shrink: 0;
  }
  .homeDiv {
    position: relative;
    font-size: 16px;
    font-family: Roboto;
    color: #f0f0f0;
    text-align: left;
    display: inline-block;
  }
  .frameDiv1 {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 51px;
  }
  .frameIcon {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
  }
  .communityDiv1 {
    gap: 18px;
  }
  .communityDiv,
  .communityDiv1,
  .frameDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frameDiv {
    justify-content: center;
    gap: 51px;
  }
  .frameIcon2 {
    position: relative;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .joinDiv {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    font-family: Roboto;
    color: #f0f0f0;
    text-align: left;
    display: inline-block;
  }
  .component4Div,
  .component5Div {
    border-radius: 5px;
    background: linear-gradient(93.57deg, #35b44b, #00803f);
    height: 38px;
    box-sizing: border-box;
    justify-content: center;
    gap: 10px;
  }
  .component4Div {
    display: flex;
    flex-direction: row;
    padding: 10px 30px 10px 25px;
    align-items: center;
  }
  .component5Div {
    padding: 10px 20px;
  }
  .component5Div,
  .frameDiv2,
  .frameNav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .frameDiv2 {
    width: 281px;
    flex-shrink: 0;
    justify-content: flex-end;
    gap: 25px;
  }
  .frameNav {
    align-self: stretch;
    padding: 40px 129px;
    box-sizing: border-box;
    justify-content: space-between;
    z-index: 0;
  }
  .ellipseIcon {
    position: absolute;
    margin: 0 !important;
    top: -300px;
    left: -468px;
    width: 1278px;
    height: 1278px;
    flex-shrink: 0;
    z-index: 1;
  }
  .vectorIcon {
    position: relative;
    width: 49.26px;
    height: 52px;
    flex-shrink: 0;
  }
  .disclaimerB {
    position: relative;
    line-height: 48.7%;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
  }
  .frameDiv3 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    z-index: 2;
    text-align: center;
    font-size: 70px;
  }
  .starfilledwideIcon {
    position: relative;
    width: 150px;
    height: 148px;
    flex-shrink: 0;
    z-index: 3;
  }
  .nFTOwnershipDiv {
    align-self: stretch;
    position: relative;
    display: inline-block;
  }
  .cantAffordTo {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .hurryUpOnly {
    margin: 0;
  }
  .frameDiv4 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    margin-top: 30px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    z-index: 4;
  }
  .ellipseIcon1 {
    position: relative;
    width: 1547px;
    height: 1547px;
    flex-shrink: 0;
    display: none;
    z-index: 5;
  }
  .phytaZenXIntellectualPropert {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    line-height: 151.7%;
    font-weight: 300;
    display: inline-block;
    height: auto;
    flex-shrink: 0;
  }
  .frameDiv5 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 199px 0 174px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    z-index: 6;
  }
  .frameDiv6 {
    padding: 0 257px 393px 177px;
    z-index: 7;
  }
  .frameDiv6,
  .frameDiv7,
  .frameDiv8,
  .frameDiv9 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
  }
  .frameDiv7 {
    overflow: hidden;
    padding: 278px 268px 0 174px;
    z-index: 8;
  }
  .frameDiv8,
  .frameDiv9 {
    padding: 103px 249px 0 178px;
    z-index: 9;
  }
  .frameDiv9 {
    padding: 0 237px 0 181px;
    gap: 28px;
    z-index: 10;
  }
  .noGuaranteesOfValue {
    position: relative;
    display: inline-block;
  }
  .frameDiv10,
  .frameDiv11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .frameDiv10 {
    width: 1067px;
    z-index: 11;
  }
  .frameDiv11 {
    align-self: stretch;
    padding: 0 212px 0 186px;
    box-sizing: border-box;
    gap: 35px;
    z-index: 12;
  }
  .ellipseIcon2 {
    position: relative;
    width: 941px;
    height: 941px;
    flex-shrink: 0;
    display: none;
    z-index: 13;
  }
  .frameDiv12 {
    align-self: stretch;
    padding: 0 208px 0 189px;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    z-index: 14;
  }
  .frameDiv12,
  .frameDiv13,
  .privacyfixedDiv {
    display: flex;
    flex-direction: column;
  
  
  
    
  }
  .frameDiv13 {
    align-self: stretch;
    padding: 0 188px 0 208px;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    z-index: 15;
  }
  .privacyfixedDiv {
  flex-basis: 0;
    background-color: #030708;
  position: relative;
  min-height:100vh;
   width:100%;
    padding: 0 ;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
    text-align: left; 
    font-size: 30px;
    color: #f0f0f0;
    font-family: Roboto;
  }
  @media screen and (max-width: 1200px) {
    .frameDiv {
      display: none;
    }
    .frameNav {
      padding-left: 100px;
      padding-right: 100px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 768px) {
    .frameNav {
      padding-left: 60px;
      padding-right: 60px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 428px) {
    .frameNav {
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
  