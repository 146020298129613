.logotrue-icon1 {
    position: absolute;
    top: 0;
    left: 0px;
    width: 243px;
    height: 45px;
    object-fit: cover;
  }
  .rectangle-div1 {
    position: relative;
    width: 243px;
    height: 50px;
    flex-shrink: 0;
    z-index: 10000;
  }
  .home-div1 {
    position: relative;
    display: inline-block;
    cursor: pointer;
   
  }
  .home-div10 {
    position: relative;
    display: inline-block;
    cursor: pointer;
    line-height: 70%;
  }
  .frame-div29 {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 45px;
    z-index: 10000;
  }
  .frame-icon5 {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
  }
  .community-div5 {
    gap: 18px;
  }
  .community-div4,
  .community-div5,
  .frame-div28 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 10000;
  }
  .frame-div28 {
    justify-content: center;
    gap: 51px;
    z-index: 10000;
  }
  .frame-icon7 {
    position: relative;
    width: 23px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frame-icon75 {
    position: relative;
    width: 26px;
    height: 32px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .join-div1 {
    position: relative;
    line-height: 30px;
    display: inline-block;
  }
  .component-4-div {
    border-radius: 5px;
    background: linear-gradient(93.57deg, #35b44b, #00803f);
    height: 38px;
    padding: 10px 17px 10px 15px;
    box-sizing: border-box;
    justify-content: center;
    gap: 10px;
  }
  .component-4-div,
  .component-5-div1,
  .frame-div30 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .component-5-div1 {
    border-radius: 5px;
    background: linear-gradient(93.57deg, #35b44b, #00803f);
    height: 38px;
 
    padding: 7px 10px;
    box-sizing: border-box;
    justify-content: center;
    gap: 10px;
    
  }
  .frame-div30 {
    width: 281px;
    flex-shrink: 0;
    justify-content: flex-end;
    gap: 12px;
   
    z-index: 10000;
  }
  .ellipse-icon9 {
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: 0;
    width: 705px;
    height: 769px;
    flex-shrink: 0;
    z-index: 10000;
    pointer-events: none;
  }
  .frame-div27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 20px 100px;  
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    position: relative; 
    z-index: 2147483647;
  
  }
  .frame-div34 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
  .naruto-is-a-japanese-manga-ser2 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    line-height: 150.7%;
   
    font-weight: 300;
    color: #f0f0f0;
    display: inline-block;
  }
  .frame-div33 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
  }
  .button-states-div1,
  .frame-div32 {
    display: flex;
    box-sizing: border-box;
  }
  .button-states-div1 {
    border-radius: 10px;
    background: linear-gradient(98.95deg, #35b44b, #00803f);
    height: 52px;
    flex-shrink: 0;
    flex-direction: row;
    padding: 15px 25px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #f0f0f0;
  }
  .frame-div32 {
    flex: 1;
    flex-direction: column;
    padding: 0px 0 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  }
  .image-2-icon {
    position: relative;
    width: 489px;
    height: 475.64px;
    flex-shrink: 0;
    /* object-fit: cover; */
  }
  .frame-div26,
  .frame-div31 {
    align-self: stretch;
    display: flex;
  }
  .frame-div31 {
    flex-direction: row;
    padding: 104px 129px;
    box-sizing: border-box;
    align-items: top;
    justify-content: center;
    gap: 111px;
    font-size: 40px;
    color: #fff;
    
  }
  .iframediv {
    align-self: stretch;
    display: flex;
  }
  .iframediv{
    flex-direction: row;
    padding: 104px 19px 220px;
    box-sizing: border-box;
    align-items: top;
    justify-content: center;
    gap: 1px;
    font-size: 40px;
    color: #fff;
    max-height: 1000px;
    height: 740px;
    position: relative;
  
  }
  .iframediv iframe{

    height: 120%;
    left: 0;
  
    top: 0;
    width: 83%;
  }
  /* .responsive-iframe iframe{

    width:"700x";
    height:"600px"
  } */
  .frame-div26 {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 10000;
  }
  .vector-icon2 {
    position: relative;
    width: 49.26px;
    height: 52px;
    flex-shrink: 0;
  }
  .problem1 {
    position: relative;
    line-height: 48.7%;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
  }
  .problem2 {
    position: relative;
    line-height: 82.7%;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
  
  }
  .frame-div36 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
   
  }
  .why-you-should-invest1 {
    align-self: stretch;
    position: relative;
    display: inline-block;
  }
  .cant-afford-to1 {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 21.5px;
    line-height: 145%;
  }
  .hurry-up-only1 {
    margin: 0;
  }
  .cant-afford-to-miss-the-3001 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    line-height: 151.7%;
    font-weight: 300;
    text-align: left;
    display: inline-block;
  }
  .frame-div35,
  .frame-div37 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    font-size: 40px;
  }
  .frame-div35 {
    padding: 76.5px 133px;
    box-sizing: border-box;
    align-items: center;
    gap: 60px;
    z-index: 1;
    text-align: center;
    font-size: 70px;
  }
  .pexels-scott-webb-1022922-remo-icon1 {
    position: absolute;
    height: 100%;
    width: 48.14%;
    top: 0;
    right: 51.86%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .pexels-scott-webb-1022922-remo-div1 {
    position: absolute;
    margin: 0 !important;
    top: 82%;
    right: -138.99px;
    width: 267.99px;
    height: 267.99px;
    flex-shrink: 0;
    z-index: 2;
  }
  .pexels-scott-webb-1022922-remo-icon2 {
    position: absolute;
    height: 100%;
    width: 48.14%;
    top: 0;
    right:  51.86%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 100000011;  
  }
  .pexels-scott-webb-1022922-remo-div2 {
    position: absolute;
    margin: 0 !important;
    top: 77.5%;
    right: 130wh;
    width: 267.99px;
    height: 267.99px;
    flex-shrink: 0;
    z-index: 1000000000;
  }
  .frame-div25 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    font-size: 16px;
  }
  .rectangle-icon6 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    width: 272px;
    height: 253px;
    object-fit: cover;
  }
  .rectangle-icon10,
  .rectangle-icon11,
  .rectangle-icon7,
  .rectangle-icon8,
  .rectangle-icon9 {
    position: absolute;
    top: 0;
    left: 286px;
    border-radius: 10px;
    width: 129px;
    height: 117px;
    object-fit: cover;
  }
  .rectangle-icon10,
  .rectangle-icon11,
  .rectangle-icon8,
  .rectangle-icon9 {
    top: 129px;
    height: 124px;
  }
  .rectangle-icon10,
  .rectangle-icon11,
  .rectangle-icon9 {
    top: 265px;
  }
  .rectangle-icon10,
  .rectangle-icon11 {
    left: 143px;
  }
  .rectangle-icon11 {
    left: 0;
  }
  .group-div1 {
    position: relative;
    width: 415px;
    height: 389px;
    flex-shrink: 0;
    z-index: 0;
  }
  .our-mission-div1 {
    position: relative;
    font-size: 40px;
    display: inline-block;
  }
  .frame-div41 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 37px;
  }
  .vector-icon3 {
    position: relative;
    width: 49px;
    height: 52px;
    flex-shrink: 0;
  }
  .frame-div40 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 47px;
  }
  .naruto-is-a-japanese-manga-ser3 {
    align-self: stretch;
    position: relative;
    font-size: 23px;
    line-height: 145.7%;
    font-weight: 300;
    display: inline-block;
  }
  .frame-div39 {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
    z-index: 1;
  }
  .ellipse-icon10 {
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: 0;
    width: 487px;
    height: 597px;
    flex-shrink: 0;
    z-index: 2;
  }
  .frame-div38 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 104px 143px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 111px;
  }
  .easy-steps-b1 {
    position: relative;
    line-height: 48px;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
  }
  .frame-div44 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 37px;
  }
  .div4 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    width: 37px;
    height: 59px;
    flex-shrink: 0;
    -webkit-text-stroke: 1px #5ce9a1;
  }
  .connect-your-p1 {
    margin-block-start: 0;
    margin-block-end: 17px;
  }
  .connect-your-wallet1 {
    position: relative;
    font-size: 16px;
    line-height: 50%;
    font-weight: 600;
    display: inline-block;
    width: 105px;
    flex-shrink: 0;
  }
  .frame-div47 {
    width: 142px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  .div5,
  .select-your-quantity1 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    flex-shrink: 0;
  }
  .div5 {
    width: 37px;
    height: 59px;
    -webkit-text-stroke: 1px #ff3183;
  }
  .select-your-quantity1 {
    font-size: 16px;
    line-height: 50%;
    width: 89px;
  }
  .frame-div46,
  .frame-div48 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .frame-div48 {
    width: 127px;
    flex-shrink: 0;
    align-items: flex-end;
    gap: 8px;
  }
  .frame-div46 {
    align-items: flex-start;
    gap: 165px;
  }
  .confirm-the-transaction1,
  .div6 {
    position: relative;
    font-weight: 600;
    display: inline-block;
  }
  .div6 {
    width: 37px;
    height: 59px;
    flex-shrink: 0;
    -webkit-text-stroke: 1px #fff65f;
  }
  .confirm-the-transaction1 {
    font-size: 16px;
    line-height: 50%;
  }
  .frame-div50 {
    width: 131px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  .div7 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    width: 37px;
    height: 59px;
    flex-shrink: 0;
    -webkit-text-stroke: 1px #6aa6ff;
  }
  .frame-div45,
  .frame-div49,
  .frame-div51 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .frame-div51 {
    width: 139px;
    flex-shrink: 0;
    align-items: flex-end;
    gap: 8px;
  }
  .frame-div45,
  .frame-div49 {
    align-items: flex-start;
    gap: 165px;
  }
  .frame-div45 {
    align-items: center;
    justify-content: center;
    font-size: 64px;
  }
  .frame-div43 {
    align-self: stretch;
    gap: 35px;
  }
  .frame-div24,
  .frame-div42,
  .frame-div43 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div42 {
    align-self: stretch;
    padding: 94px 170px 207px;
    box-sizing: border-box;
    color: #fff;
  }
  .frame-div24 {
    position: relative;
    background-color: #030708;
    width: 100%;
    overflow: hidden;
    text-align: left;
    font-size: 70px;
    color: #f0f0f0;
    font-family: Roboto;
  }
  @media screen and (max-width: 1200px) {
    .frame-div28 {
      display: none;
    }
    .frame-div27 {
      padding-left: 100px;
      padding-right: 100px;
      box-sizing: border-box;
    }
    .frame-div32 {
      flex: unset;
      align-self: stretch;
    }
    .frame-div31 {
      flex-direction: column;
    }
    .frame-div31,
    .frame-div35 {
      padding-left: 100px;
      padding-right: 100px;
      box-sizing: border-box;
    }
    .frame-div39 {
      flex: unset;
      align-self: stretch;
    }
    .frame-div38 {
      padding-left: 100px;
      padding-right: 100px;
      box-sizing: border-box;
    }
    .frame-div38,
    .frame-div45 {
      flex-direction: column;
    }
    .frame-div42 {
      padding-left: 100px;
      padding-right: 100px;
      box-sizing: border-box;
    }
  }

  @media screen and (min-width: 1400px) {
  .frame-div34 {

    font-size:33px;
  }
  
  }

  @media screen and (min-width: 1600px) {
    .frame-div34 {
  
      font-size:35px;
    }
    .iframediv{
      height:950px;
    }
    .iframediv iframe{

      height: 120%;
      left: 0;
    
      top: 0;
      width: 87%;
    }
  
    }
.globtext{font-size: 25px;}

    @media screen and (min-width: 1500px) {
      .home-div10{
        
      margin-bottom: 5%;
       }
       .globtext{
        font-size: 28px;
     
       }

    }
  @media screen and (max-width: 768px) {
    .frame-div27,
    .frame-div31,
    .frame-div35,
    
    .frame-div42 {
      padding-left: 60px;
      padding-right: 60px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 428px) {
    .frame-div27,
    .frame-div31,
    .frame-div35,
    .frame-div38 {
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
    .frame-div49 {
      flex-direction: column;
    }
    .frame-div42 {
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width:1500px) {
    .pexels-scott-webb-1022922-remo-div1 {
     top:81%;

    }
    .pexels-scott-webb-1022922-remo-div2 {
      top:76.2%;
 
     }
    }

  @media screen and (max-width:1300px) {
   
    .frame-div28{
      font-size:16px;
      gap:30px;
    }
    .frame-div29{
      font-size:16px;
      gap:30px;
      
    }
    .cant-afford-to1{
line-height: 135%;
      font-size: 22.5px;
    }
    .naruto-is-a-japanese-manga-ser3{
      font-size: 22.5px;
      line-height: 135.7%;
    }
  }
  
  @media screen and (min-width: 1600px) {

.frame-div28{
  font-size:20px;
  gap:58px;
}
.frame-div29{
  font-size:20px;
  gap:58px;
}
.frame-icon75{

  width: 28px;
    height: 36px;
}

.component-4-div{
  height: 45px;
  padding: 10px 18px 10px 16px;

}
.component-5-div1{height: 45px;
width: 300px;
  padding: 10px 18px 10px 18px;
}

.join-div1{

  font-size: 18.5px;

}
.cant-afford-to1{
line-height: 154%;
  font-size: 25px;
}
.naruto-is-a-japanese-manga-ser3{
  font-size: 25px;
  line-height: 154.7%;
}
  }