.vectorIcon {
    position: absolute;
    margin: 0 !important;
    top: -1px;
    left: -61px;
    width: 49px;
    height: 52px;
    flex-shrink: 0;
    z-index: 0;
    pointer-events: none;
  }
  .roadmapB {
    position: relative;
    line-height: 48.69%;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
    z-index: 1;
  }
  .frameDiv3 {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
    left: -6%;
  }
  .wheresTheMoneyBeingInvest {
    align-self: stretch;
    position: relative;
    font-size: 40px;
    display: inline-block;
  }
  .frameDiv2 {
    width: 634px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 37px;
  }
  .b,
  .frameDiv5 {
    position: relative;
    
    
  }
  .b {
    display: inline-block;
    color:white;
    font-size: 30px;
  }
  .frameDiv5 {
    border-radius: 30px;
    border: 4px solid #5ce9a1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 18px 26px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 23%;
  }
  .toGetTheRightJumpstartWe {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    display: inline-block;
  }
  .frameDiv6 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 23px;
  }
  .frameDiv,
  .frameDiv1,
  .frameDiv4,
  .roadMapDiv {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    
  }
  .roadMapDiv {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: 0 1px 0#000;
    flex-direction: row;
    padding: 15px 10px;
    box-sizing: border-box;
    align-items: center;  
    gap: 23px;
    width: 91%;
  }
  .frameDiv,
  .frameDiv1,
  .frameDiv4 {
    flex-direction: column;
  }
  .frameDiv4 {
    align-items: flex-start;
    gap: 41px;
    text-align: left;
    font-size: 20px;
  }
  .frameDiv,
  .frameDiv1 {
    box-sizing: border-box;
  }
  .frameDiv1 {
    padding: 10px 42.5px;
    align-items: center;
    gap: 69px;
  }
  .frameDiv {
    padding-top: 178px  ;
    padding-bottom: 97px;
    padding-left:104px ;
    align-items: flex-start;
  }
  .vectorIcon1 {
    position: relative;
  bottom:-8px;
    width: 60px;
    height: 59px;
    flex-shrink: 0;
    pointer-events: none;
  }
  .vectorIcon2 {
    position: relative;
 
    width: 60px;
    height: 59px;
    flex-shrink: 0;
    pointer-events: none;
  }
  .ourTeamB {
    position: relative;
    line-height: 48.69%;
    display: inline-block;
    -webkit-text-stroke: 2px rgba(92, 233, 161, 0.5);
  }
  .frameDiv13 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 28px;
  }
  .rectangleDiv {
    position: absolute;
    height: 100.6%;
    width: 100%;
    top: 0%;
    right: 0;
    bottom: 0%;
    left: 0;
    border-radius: 20px;
    background-color: #282828;
  }
  .rectangleIcon {
    position: relative;
    border-radius: 20px;
    width: 318px;
    height: 172px;
    flex-shrink: 0;
    object-fit:contain;
  }
  .rectangleIcon2 {
    position: relative;
    border-radius: 20px;
    width: 270px;
    height:160px;
    flex-shrink: 0;
    object-fit: contain;
  }
  .founderCEO,
  .mikeAndersonDiv {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .mikeAndersonDiv {
    font-weight: 600;
  }
  .founderCEO {
    font-size: 20px;
  }
  .frameDiv16,
  .frameDiv17 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameDiv17 {
    height: 83px;
    flex-shrink: 0;
    padding-right: 10px;
    gap: 8px;
  }
  .frameDiv16 {
    position: absolute;
    height: 96.2%;
    width: 93.94%;
    top: 5%;
    right: 4.39%;
    bottom: 3.8%;
    left: 2.67%;
    gap: 27px;
  }
  .component1Div,
  .component2Div {
    position: relative;
    width: 387px;
    height: 695px;
    flex-shrink: 0;
    margin-top: 100px;
  }
  .component3Div {
    position: relative;
    width: 387px;
    height: 695px;
    flex-shrink: 0;
    margin-top: 100px;
    margin-left: 12%;

   
  }
  li::marker {
    font-size: 1.3em;
  }
  .component4Div {
    position: relative;
    width: 387px;
    height: 695px;  
    flex-shrink: 0;
    margin-top: 100px;
    margin-right: 14%;
   
  }
  .frameDiv14,
  .frameDiv15 {
    align-self: stretch;
    align-items: center;
  }
  .frameDiv15 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 24px;
  }
  .frameDiv14 {
    flex-direction: column;
    padding: 0 31.5px;
    box-sizing: border-box;
    gap: 9px;
  
    font-size: 48px;
  }
  .frameDiv12,
  .frameDiv14,
  .frameDiv23 {
    display: flex;
    justify-content: flex-start;
  }
  .frameDiv12 {
    align-self: stretch;
    flex-direction: column;
    padding: 81px 25px 107px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
    text-align: left;
  }
  .frameDiv23 {
    flex-direction: row;
    align-items: flex-end;
    gap: 19px;
  }

  
  .frequentlyAksedQuestions {
    position: relative;
    display: inline-block;
    width: 813px;
  }
  .howManyNFTsWeveGot {
    flex: 1;
    position: relative;
    font-weight: 500;
    display: inline-block;
    font-size: 27px;
  }
  .frameDiv26 {
    align-self: stretch;
    backdrop-filter: blur(200px);
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .weAllHaveThePowerToChang {
    flex: 1;
    position: relative;
    display: inline-block;
    line-height: 120%;
    font-size: 22px;
  }
  .weAllHaveThePowerToChang2 {
    flex: 1;
    position: relative;
    display: inline-block;
    
  }
  .fAQGlassDiv,
  .fAQGlassDiv1,
  .frameDiv27 {
    align-self: stretch;
    display: flex;
    box-sizing: border-box;
   
  }
  .frameDiv27 {
    flex-direction: row;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
  }
  .fAQGlassDiv,
  .fAQGlassDiv1 {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    align-items: center;
  }
  .fAQGlassDiv {
    flex-direction: column;
    padding: 10px 20px;
    justify-content: flex-start;
    color: #fff;
  }
  .fAQGlassDiv1 {
    backdrop-filter: blur(150px);
    flex-direction: row;
    padding: 15px 30px;
    justify-content: center;
    width:90%;
  }
  .frameDiv22,
  .frameDiv24,
  .frameDiv25 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .frameDiv25 {
    align-self: stretch;
    align-items: flex-start;
    gap: 35px;
    text-align: left;
    font-size: 20px;
    color: #f8f8f8;
  }
  .frameDiv22,
  .frameDiv24 {
    align-items: center;
  }
  .frameDiv24 {
    width: 1067px;
    gap: 46px;
    font-size: 40px;
  }
  .frameDiv22 {
    align-self: stretch;
    padding: 80px 10px 90px;
    box-sizing: border-box;
    gap: 62px;
    color: #f0f0f0;
  }
  .joinOurAwesome {
    font-weight: 600;
    font-family: Roboto;

  }
  .joinOurAwesomeCommunity {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .getEarlyToTheParty {
    margin: 0;
  }
  .frameDiv31 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frameIcon {
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .whitelistDiv {
    position: relative;
    font-weight: 500;
    display: inline-block;
  }
  .outLinedButtonStates {
    align-self: stretch;
    border-radius: 10px;
    border: 3px solid #fff;
    box-sizing: border-box;
    position: relative;
    width: 228px;
    flex-shrink: 0;
    padding: 15px 25px;
    gap: 10px;
  }
  .frameDiv33,
  .outLinedButtonStates,
  .outLinedButtonStates1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frameDiv33 {
    height: 63px;
  }
  .outLinedButtonStates1 {
    align-self: stretch;
    border-radius: 10px;
    background-color: #249938;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    position: relative;
    width: 228px;
    flex-shrink: 0;
    padding: 15px 25px;
    gap: 10px;
  }
  .frameDiv32 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
    text-align: left;
    font-size: 24px;
  }
  .frameDiv30 {
    width: 1106px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    z-index: 0;
  }
  .hangingLeafIcon {
    position: absolute;
    margin: 0 !important;
    top: 314px;
    left: calc(50% - -203.5px);
    width: 89px;
    height: 346px;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  .frameDiv29 {
    border-radius: 15px;
    border: 3px solid #fff;
    box-sizing: border-box;
    position: relative;
    width: 1067px;
    height: 314px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .logoTrueIcon {
    position: absolute;
    top: 0;
    left: 4px;
    width: 243px;
    height: 160px;
    object-fit: cover;
  }
  .rectangleDiv3 {
    position: relative;
    width: 243px;
    height: 50px;
    flex-shrink: 0;
  }
  .frameDiv37,
  .frameDiv38 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameDiv37 {
    width: 229px;
    height: 50px;
    flex-shrink: 0;
    align-items: center;
  }
  .aliquaIdFugiatNostrudIrure {
    position: relative;
    line-height: 151.69%;
    display: inline-block;
    width: 458px;
    flex-shrink: 0;
  }
  .frameDiv39 {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameDiv36 {
    gap: 40px;
  }
  .frameDiv35,
  .frameDiv36,
  .frameDiv40 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frameDiv40 {
    margin-top: -80px;
  }
  .frameDiv35 {
    font-size: 16px;
    color: #fff;
  }
  .frameIcon2,
  .frameIcon5 {
    position: relative;
    width: 41px;
    height: 41px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frameIcon5 {
    width: 31px;
    height: 31px;
  }
  .frameDiv28,
  .frameDiv41 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 30.75px;
  }
  .frameDiv28 {
    align-self: stretch;
    flex-direction: column;
    padding: 74px 106px;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 129px;
    font-size: 40px;
    color: #f0f0f0;
  }
  .frameDiv44 {
    width: 209px;
    flex-shrink: 0;
    align-items: center;
  }
  .frameDiv43,
  .frameDiv44,
  .frameDiv45 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .frameDiv45 {
    width: 100wh;
    flex-shrink: 0;
    align-items: flex-start;
  }
  .frameDiv43 {
    align-self: stretch;
    align-items: center;
  }
  .frameDiv42,
  .v222ndFlowDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameDiv42 {
    align-self: stretch;
    background-color:#030708;
    padding: 20px 85px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #000;
  }
  .v222ndFlowDiv {
    position: relative;
    background-color: #030708;
    width: 100%;
    text-align: center;
    font-size: 70px;
    color: #fff;
    font-family: Roboto;
  }
  @media screen and (max-width: 1200px) {
    .component2Div {
      margin-top: 30px;
    }
    .frameDiv15 {
      flex-direction: column;
    }
    .frameDiv24 {
      width: 90%;
    }
    .frameDiv29 {
      max-width: 80%;
    }
  }
  .ellipse-icon9{

    pointer-events: none;
  }
  
  .home-div1 {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  @media screen and (min-width: 1500px) {

    .frameDiv5{
      margin-left: 28% ;
    }
  }
  @media screen and (min-width: 1600px) {

    .frameDiv5{
      margin-left: 32% ;
    }
    li::marker {
      font-size: 1.6em;
    }
    .teamtext{
      font-size: 16px;
    }
    
  }

  
  @media screen and (min-width: 1850px) {

    .frameDiv5{
      margin-left: 35.5% ;
    }

  }
  @media screen and (min-width: 2050px) {

    .frameDiv5{
      margin-left: 39.5% ;
    }

  }

  .teamtext{
    font-size: 16px;
  }